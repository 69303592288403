import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import block from "../../assets/integrity.jpg";
import block1 from "../../assets/innovation.jpg";
import block2 from "../../assets/teamwork.jpg";
import block3 from "../../assets/quality.jpg";

import "./style.scss";

export const CultureBlock = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll(".animate").forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);
  return (
    <>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col lg={6} md={12} className="customCol">
            <>
              <p
                className="culture"
                style={{
                  textAlign: "left",
                  padding: "10px",
                  display: "contents",
                }}
              >
                TRUST & INTEGRITY
              </p>
              <p
                className="cultureDesc"
                style={{ paddingLeft: "50px", textAlign: "justify" }}
              >
                We embody unwavering integrity, setting a high standard for
                trustworthiness. We inspire each other to be truthful and fair
              </p>
            </>
          </Col>
          <Col lg={6} md={12}>
            <Image src={block} className="image" />
          </Col>
        </Row>
        <br></br>
        <br></br>
        <br></br>
        <Row style={{ width: "100%" }}>
          <Col lg={6} md={12} className="customCol">
            <Image src={block1} className="image" />
          </Col>
          <Col
            lg={6}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <>
              <p
                className="culture"
                style={{
                  textAlign: "left",
                  padding: "10px",
                }}
              >
                INNOVATION
              </p>
              <p
                className="cultureDesc"
                style={{
                  paddingRight: "30px",
                  textAlign: "justify",
                }}
              >
                We dedicate our efforts and resources to continuous innovation.
                Fostering a dynamic and sustainable culture, we strive for
                uniqueness and distinctiveness.
              </p>
            </>
          </Col>
        </Row>
        <Row style={{ width: "100%", marginTop: "4rem" }}>
          <Col lg={6} md={12} className="customCol">
            <>
              <p
                className="culture"
                style={{ textAlign: "right", padding: "10px" }}
              >
                TEAM-WORK
              </p>
              <p
                className="cultureDesc"
                style={{
                  paddingLeft: "45px",
                  textAlign: "justify",
                }}
              >
                We recognize that collaboration, support, and teamwork within
                our company yield optimal results for our clients. Together, we
                achieve excellence
              </p>
            </>
          </Col>
          <Col lg={6} md={12}>
            <Image src={block2} className="image" />
          </Col>
        </Row>
        <br></br>
        <br></br>
        <br></br>
        <Row style={{ width: "100%" }}>
          <Col lg={6} md={12} className="customCol">
            <Image src={block3} className="image" />
          </Col>
          <Col
            lg={6}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <>
              <p
                className="culture"
                style={{
                  textAlign: "left",
                  padding: "10px",
                }}
              >
                QUALITY
              </p>
              <p
                className="cultureDesc"
                style={{
                  textAlign: "left",
                  padding: "10px",
                }}
              >
                We aim for excellence in what we do.We take the extra mile and
                we deliver perfection.
              </p>
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
};
