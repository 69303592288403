import { ExploreSlider } from "../../../components";

//slider images

import exhibition3 from "../../../assets/explore-slider/exhibition3.webp";
import exhibition4 from "../../../assets/explore-slider/exhibition4.webp";
import exhibition5 from "../../../assets/explore-slider/exhibition5.webp";
import exhibition6 from "../../../assets/explore-slider/exhibition6.webp";

export const Exhibition = () => {
  const sliderImages = [exhibition3, exhibition4, exhibition5, exhibition6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
