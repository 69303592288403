import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Services } from "./pages/services";
import { Gallery } from "./pages/gallery";
import { Footer, NavBar, StickyButtons } from "./components";
import { SendQuery } from "./pages/SendQuery";
import { NotFoundPage } from "./pages/NotFound";
import { AboutUs } from "./pages/about-us/index";
import { ExploreMore } from "./pages/ExploreMore";
import { LiveEvent } from "./pages/services/live-events";
import { Exhibition } from "./pages/services/exhibition";
import { Weddings } from "./pages/services/wedding";
import { Conferences } from "./pages/services/conference";
import { GalaDinners } from "./pages/services/dinners";
import { ProductLaunches } from "./pages/services/product";
import { Awards } from "./pages/services/awards";
import { Seminars } from "./pages/services/seminar";
import { Festivals } from "./pages/services/festival";
import { Trades } from "./pages/services/trade";
import { Production } from "./pages/services/production";
import { Interior } from "./pages/services/interior";
import { HomePage } from "./pages/home-page/index";
import { AudioSolutions } from "./pages/technical-expertise/audio-solutions";
import { VideoSolutions } from "./pages/technical-expertise/video-solutions";
import { LightSolutions } from "./pages/technical-expertise/light-solutions";
import { MediaSolutions } from "./pages/technical-expertise/media-solutions";
import { TrussingSolutions } from "./pages/technical-expertise/trussing-solutions";
import { ProductionSolutions } from "./pages/technical-expertise/production";
import { StaggingSolutions } from "./pages/technical-expertise/stagging-solutions";
import { imageObj } from "./components/data";

import "./App.scss";

function App() {
  return (
    <div>
      <StickyButtons />
      <div
        style={{
          top: 0,
          zIndex: 1,
          width: "100%",
          position: "fixed",
          background: "rgb(0,0,0,0.5)",
        }}
      >
        <NavBar />
      </div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/send-query" element={<SendQuery />} />
        <Route path="/explore-here" element={<ExploreMore />} />
        <Route path="/services/live-events" element={<LiveEvent />} />
        <Route path="/services/exhibitions" element={<Exhibition />} />
        <Route path="/services/weddings" element={<Weddings />} />
        <Route path="/services/conferences" element={<Conferences />} />
        <Route path="/services/gala-dinners" element={<GalaDinners />} />
        <Route
          path="/services/product-launches"
          element={<ProductLaunches />}
        />
        <Route path="/services/awards" element={<Awards />} />
        <Route path="/services/seminars" element={<Seminars />} />
        <Route path="/services/festivals" element={<Festivals />} />
        <Route path="/services/trade-shows" element={<Trades />} />
        <Route path="/services/production-designing" element={<Production />} />
        <Route path="/services/interior-fitouts" element={<Interior />} />
        <Route
          path="/technical-expertise/audio-solutions"
          element={<AudioSolutions imageObj={imageObj} />}
        />
        <Route
          path="/technical-expertise/video-solutions"
          element={<VideoSolutions imageObj={imageObj} />}
        />
        <Route
          path="/technical-expertise/media-solutions"
          element={<MediaSolutions imageObj={imageObj} />}
        />
        <Route
          path="/technical-expertise/light-solutions"
          element={<LightSolutions imageObj={imageObj} />}
        />
        <Route
          path="/technical-expertise/trussing-solutions"
          element={<TrussingSolutions imageObj={imageObj} />}
        />
        <Route
          path="/technical-expertise/production-solutions"
          element={<ProductionSolutions imageObj={imageObj} />}
        />
        <Route
          path="/technical-expertise/stagging-solutions"
          element={<StaggingSolutions imageObj={imageObj} />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
