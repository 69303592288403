import { ExploreSlider } from "../../../components";

//slider images

import gala3 from "../../../assets/explore-slider/galadinner3.webp";
import gala4 from "../../../assets/explore-slider/galadinner4.webp";
import gala5 from "../../../assets/explore-slider/galadinner5.webp";
import gala6 from "../../../assets/explore-slider/galadinner6.webp";

export const GalaDinners = () => {
  const sliderImages = [gala3, gala4, gala5, gala6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
