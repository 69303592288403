import React from "react";

import { Link } from "react-router-dom";
import notFoundImage from "../assets/notfound.png";

import "./NotFound.scss";
import { NavBar } from "../components";

export const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <img
        src={notFoundImage}
        alt="Page Not Found"
        className="not-found-image"
      />
      <h2>Oops! Page Not Found</h2>
      <p>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>
      <Link to="/" className="home-link">
        Go to Homepage
      </Link>
    </div>
  );
};
