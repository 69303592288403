import { ExploreSlider } from "../../../components";

//slider images

import wedding3 from "../../../assets/explore-slider/weddings3.webp";
import wedding4 from "../../../assets/explore-slider/weddings4.webp";
import wedding5 from "../../../assets/explore-slider/weddings5.webp";
import wedding6 from "../../../assets/explore-slider/weddings6.webp";

export const Weddings = () => {
  const sliderImages = [wedding3, wedding4, wedding5, wedding6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
