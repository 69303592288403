import React from "react";

// import { Image } from "react-bootstrap";
// import image from "../../assets/Images/1.png";
import { ExploreSlider } from "../../components";

import "./style.scss";
import { useLocation } from "react-router-dom";

export const ExploreMore = () => {
  const { state } = useLocation();
  return (
    <div>
      {/* <Image
        style={{
          objectFit: "cover",
          position: "absolute",
          zIndex: -1,
        }}
        src={image}
        width={"100%"}
        height={"500"}
      />
      <div className="explore-more">
        <h1 className="explore">Explore Here</h1>
        <h2 className="discription">
          Welcome to Prime Events & Exhibitions, where we craft unforgettable
          <br />
          experiences tailored to your vision, turning moments into cherished
          memories.
        </h2>
      </div> */}
      <section style={{ background: "#000" }}>
        <ExploreSlider images={state} style={{ width: "70%" }} />
      </section>
    </div>
  );
};
