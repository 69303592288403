import React from "react";
import "./style.scss";
import {
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

const socialLinks = [
  {
    platform: "whatsapp",
    link: "https://api.whatsapp.com/send/?phone=971581291260&text&type=phone_number&app_absent=0",
    text: "Whatsapp Us",
  },
  {
    platform: "facebook",
    link: "https://www.facebook.com/profile.php?id=61550717297348&mibextid=LQQJ4d",
    text: "Follow Us",
  },
  {
    platform: "instagram",
    link: "https://www.instagram.com/peep.group?igshid=MXVyOWh2cGRhcWUweg%3D%3D&utm_source=qr",
    text: "Stay Updated",
  },
  {
    platform: "linkedin",
    link: "https://www.linkedin.com/company/prime-events-exhibitions-productions/",
    text: "Connect Us",
  },
];

const socialIcons = {
  whatsapp: <FaWhatsapp className="icon" />,
  facebook: <FaFacebookF className="icon" />,
  instagram: <FaInstagram className="icon" />,
  linkedin: <FaLinkedinIn className="icon" />,
};

const StickyButton = ({ link, platform, text }) => {
  return (
    <li>
      <a href={link} target="blank" className={platform}>
        {socialIcons[platform]}
        <span className="label">{text}</span>
      </a>
    </li>
  );
};

export const StickyButtons = () => {
  return (
    <ul className="sticky-buttons">
      {socialLinks.map((linkInfo) => (
        <StickyButton
          key={linkInfo.platform}
          link={linkInfo.link}
          platform={linkInfo.platform}
          text={linkInfo.text}
        />
      ))}
    </ul>
  );
};
