import { Col, Image, Row, Container } from "react-bootstrap";
import { useState } from "react";
import {
  CultureContainer,
  // WorkBlock,
  Footer,
} from "../../components/index";
import bgImg from "../../assets/about/aboutus.webp";
import { OurStory } from "../../components/our-story";
import { QueryModal } from "../../components/queryModal";
import { ScrollSlider } from "../../components/scroll-slider";

import "./style.scss";

import man1 from "../../assets/man1.jpeg";
import man2 from "../../assets/man2.png";
import man3 from "../../assets/man3.jpg";
import man4 from "../../assets/man4.jpg";
// import team1 from "../../assets/team.png";
// import team2 from "../../assets/team2.png";

export const AboutUs = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  return (
    <div>
      <Image
        style={{
          objectFit: "cover",
          position: "absolute",
          zIndex: -1,
        }}
        src={bgImg}
        width={"100%"}
        height={"500"}
      />
      <div className="aboutUs">
        <h1 className="about">ABOUT US</h1>
        <h2 className="discription">
          Welcome to Prime Events, Exhibitions & Productions, where we craft
          unforgettable
          <br />
          experiences tailored to your vision, turning moments into cherished
          memories.
        </h2>
      </div>
      <br></br>
      <br></br>
      <div>
        <OurStory />
      </div>
      <CultureContainer />
      <br></br>
      <br></br>
      <br></br>
      <div>
        {/* <p className="heading ">Who We Are ?</p> */}
        <p className="heading2">Our Clients</p>
        <br></br>
        <ScrollSlider />
        <br></br>
        <br></br>
        <br></br>
        <Container
          fluid
          style={{
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p className="heading2">Meet Our Team</p>
          <Row>
            <Col
              lg={3}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Image src={man4} className="team" />

              <center>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "28px",
                    lineHeight: "5px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Tahir Suleman
                </p>
                <p
                  style={{
                    fontFamily: "sans-serif",
                    lineHeight: "18px",
                  }}
                >
                  <span>Founder & CEO</span>
                  <br></br>
                  {/* <span>info@peepgroup.com</span>
                  <span>(+971588580901)</span> */}
                </p>
              </center>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
              lg={3}
              md={6}
            >
              <Image src={man2} width={300} className="team" />
              <center>
                <p
                  style={{
                    fontFamily: "roboto",
                    fontSize: "28px",
                    lineHeight: "5px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Jan Michael
                </p>
                <p
                  style={{
                    fontFamily: "sans-serif",
                    lineHeight: "18px",
                  }}
                >
                  <span>Sales & Marketing Manager</span>
                  <br></br>
                  {/* <span>sales@peepgroup.com</span>
                  <span>(+971569271963)</span> */}
                </p>
              </center>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              lg={3}
              md={6}
            >
              <Image
                src={man3}
                width={300}
                className="team"
                style={{ height: "335px" }}
              />
              <center>
                <p
                  style={{
                    fontFamily: "roboto",
                    fontSize: "28px",
                    lineHeight: "5px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Joni Mercado
                </p>
                <p
                  style={{
                    fontFamily: "sans-serif",
                    lineHeight: "18px",
                  }}
                >
                  <span>Sales Project Manager</span>
                  <br></br>
                  {/* <span>technical@peepgroup.com</span>
                  <span>(+971529115965)</span> */}
                </p>
              </center>
            </Col>
            <Col
              lg={3}
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image src={man1} width={300} className="team" />
              <center>
                <p
                  style={{
                    fontFamily: "roboto",
                    fontSize: "28px",
                    lineHeight: "5px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Muhammad Irfan
                </p>
                <p
                  style={{
                    fontFamily: "sans-serif",
                    lineHeight: "18px",
                  }}
                >
                  <span>Technical Manager</span>
                  <br></br>
                  {/* <span>operations@peepgroup.com</span>
                  <span>(+971554463746)</span> */}
                </p>
              </center>
            </Col>
          </Row>
        </Container>
      </div>
      <QueryModal show={show} handleClose={() => handleClose()} />
      <br></br>
      {/* <Footer id="FooterEnd" modalOpen={() => handleShow()} /> */}
    </div>
  );
};
