import { ExploreSlider } from "../../../components";

//slider images
import fitout3 from "../../../assets/explore-slider/fitout3.webp";
import fitout4 from "../../../assets/explore-slider/fitout4.webp";
import fitout5 from "../../../assets/explore-slider/fitout5.jpg";
import fitout6 from "../../../assets/explore-slider/fitout6.webp";

export const Interior = () => {
  const sliderImages = [fitout3, fitout4, fitout5, fitout6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
