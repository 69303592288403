import { ExploreSlider } from "../../../components";

//slider images

import conference3 from "../../../assets/explore-slider/conference3.webp";
import conference4 from "../../../assets/explore-slider/conference4.webp";
import conference5 from "../../../assets/explore-slider/conference5.webp";
import conference6 from "../../../assets/explore-slider/conference6.webp";

export const Conferences = () => {
  const sliderImages = [conference3, conference4, conference5, conference6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider
          images={sliderImages}
          style={{ width: "100%", objectFit: "cover" }}
        />
      </section>
    </div>
  );
};
