// HomeSlider.js

import React from "react";
import Slider from "react-slick";
import Button from "react-bootstrap/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import home1 from "../../assets/home-slider/home1.webp";
import home2 from "../../assets/home-slider/home2.webp";
import home3 from "../../assets/home-slider/home3.webp";
import home4 from "../../assets/home-slider/home4.webp";
import home5 from "../../assets/home-slider/home5.webp";
import home6 from "../../assets/home-slider/home6.webp";
import home7 from "../../assets/home-slider/home7.webp";
import home8 from "../../assets/home-slider/home8.webp";
import home9 from "../../assets/home-slider/home9.webp";
import home10 from "../../assets/home-slider/home10.webp";
import home11 from "../../assets/home-slider/home11.jpeg";
import "./style.scss";
import { Link } from "react-router-dom";

export const HomeSlider = () => {
  const settings = {
    // dots: true,
    arrows: false,
    fade: true,
    speed: 500,
    infinite: true,
    autoplay: true,
    cssEase: "linear",
    swipeToSlide: true,
    autoplaySpeed: 2000,
  };

  const slides = [home1, home2, home3, home4, home5, home6, home7,  home8, home9, home10, home11, ];

  return (
    <div>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img src={slide} alt="" style={{ height: "35rem" }} />
            <div className="text-container">
              {/* <h2 className="title">Prime Events & Exhibitions</h2> */}
              {/* <p className="quote">Your Complete Event Solutions</p>
              <Button variant="secondary">
                <Link to="/services" className="services-btn">
                  Our Services
                </Link>
              </Button> */}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
