import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DisplayBox } from "../index";
import "./style.scss";

export const WorkBlock = ({ textOne, textTwo, imgObject, showButton }) => {
  const [fontSize, setFontSize] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowAnimation(true);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    if (textTwo === "FEATURED EVENTS") {
      setFontSize("25px");
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [textTwo]);

  return (
    <div style={{ marginTop: "50px" }}>
      <p className="heading ">{textOne}</p>
      <p className="heading2">{textTwo}</p>
      <Container
        ref={ref}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          flexDirection: "column",
        }}
        className={showAnimation ? "your-component fade-in" : ""}
      >
        {imgObject?.map((item) => (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {item?.col?.map((item) => (
              <Col
                lg={4}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DisplayBox
                  img1={item.img1}
                  img2={item.img2}
                  text1={item.text1}
                  text2={item.text2}
                  fontSize={fontSize}
                  showButton={showButton}
                  sliderImages={item.sliderImages}
                  link={item.link}
                />
              </Col>
            ))}
          </Row>
        ))}
      </Container>
      {/* <center>
        <p className="discoverMore">DISCOVER ALL SERVICES</p>
      </center> */}
    </div>
  );
};
