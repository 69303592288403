import storyLogo from "../../assets/Images/OurStory.png";

import "./style.scss";

export const OurStory = () => {
  return (
    <div className="storyContainer">
      <div>
        <center>
          {/* <span
            style={{
              fontSize: "28px",
            }}
          >
            About US
          </span> */}
          <br></br>
          <span
            style={{
              fontSize: "50px",
            }}
          >
            Our Story
          </span>
        </center>
        <p className="story">
          We have been at the forefront of the events and exhibitions industry
          in the Middle East since 2013. Our journey, marked by relentless
          progress and dedicated hard work, has expanded our reach across the
          Gulf, Europe, and the Middle East. Born in the golden sands of the
          Gulf. Our expertise in crafting memorable occasions and our attention
          to detail have set us apart, making us a leading name in the region.
          Our team, fueled by ambition and guided by passion, excels in
          transforming creativity into tangible, innovative ideas for every
          event we undertake.
        </p>
      </div>
      <img
        src={storyLogo}
        alt=""
        className="storyImag"
        style={{
          height: "400px",
          // width: "600px",
        }}
      />
    </div>
  );
};
