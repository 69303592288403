import audio1 from "../assets/technical-expertise/audio1.webp";
import audio2 from "../assets/technical-expertise/audio2.webp";
import audio3 from "../assets/technical-expertise/audio3.webp";
import audio4 from "../assets/technical-expertise/audio4.webp";
import audio5 from "../assets/technical-expertise/audio5.webp";

import video1 from "../assets/technical-expertise/video1.webp";
import video2 from "../assets/technical-expertise/video2.webp";
import video3 from "../assets/technical-expertise/video3.webp";
import video4 from "../assets/technical-expertise/video4.webp";
import video5 from "../assets/technical-expertise/video5.webp";

import lighting1 from "../assets/technical-expertise/lighting1.webp";
import lighting2 from "../assets/technical-expertise/lighting2.webp";
import lighting3 from "../assets/technical-expertise/lighting3.webp";
import lighting4 from "../assets/technical-expertise/lighting4.webp";
import lighting5 from "../assets/technical-expertise/lighting5.webp";

import trussing1 from "../assets/technical-expertise/trussing1.webp";
import trussing2 from "../assets/technical-expertise/trussing2.webp";
import trussing3 from "../assets/technical-expertise/trussing3.webp";
import trussing4 from "../assets/technical-expertise/trussing4.webp";
import trussing5 from "../assets/technical-expertise/trussing5.webp";

import stagging1 from "../assets/technical-expertise/stagging1.webp";
import stagging2 from "../assets/technical-expertise/stagging2.webp";
import stagging3 from "../assets/technical-expertise/stagging3.webp";
import stagging4 from "../assets/technical-expertise/stagging4.webp";
import stagging5 from "../assets/technical-expertise/stagging5.webp";

import media1 from "../assets/technical-expertise/media1.webp";
import media2 from "../assets/technical-expertise/media2.webp";
import media3 from "../assets/technical-expertise/media3.webp";
import media4 from "../assets/technical-expertise/media4.webp";
import media5 from "../assets/technical-expertise/media5.webp";

import production1 from "../assets/technical-expertise/production1.webp";
import production2 from "../assets/technical-expertise/production2.webp";
import production3 from "../assets/technical-expertise/production3.webp";
import production4 from "../assets/technical-expertise/production4.webp";
import production5 from "../assets/technical-expertise/production5.webp";

const imageObj = [
  {
    serviceName: "Audio Solutions",
    sliderImages: [audio1, audio2, audio3, audio4, audio5],
  },
  {
    serviceName: "Video Solutions",
    sliderImages: [video1, video2, video3, video4, video5],
  },
  {
    serviceName: "Lighting Solutions",
    sliderImages: [lighting1, lighting2, lighting3, lighting4, lighting5],
  },
  {
    serviceName: "Trussing Solutions",
    sliderImages: [trussing1, trussing2, trussing3, trussing4, trussing5],
  },
  {
    serviceName: "Stagging Solutions",
    sliderImages: [stagging1, stagging2, stagging3, stagging4, stagging5],
  },
  {
    serviceName: "Media Solutions",
    sliderImages: [media1, media2, media3, media4, media5],
  },
  {
    serviceName: "Production & Cladding",
    sliderImages: [
      production1,
      production2,
      production3,
      production4,
      production5,
    ],
  },
];

export { imageObj };
