import React, { useEffect, useRef, useState } from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { RiMapPin2Fill } from "react-icons/ri";
import { BsFillTelephoneFill } from "react-icons/bs";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";

import logo from "../../assets/footer-logo.jpeg";

import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillInstagram,
  // AiFillPhone,
  // AiOutlineMail,
  // AiOutlineWhatsApp,
} from "react-icons/ai";
import { LiaGemSolid } from "react-icons/lia";
// import { BiLocationPlus } from "react-icons/bi";

import "./style.scss";
export const Footer = ({ id, modalOpen }) => {
  const currentYear = new Date().getFullYear();
  const [showAnimation, setShowAnimation] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowAnimation(false);
          }
        });
      },
      {
        threshold: 0.1, // Adjust as needed
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <MDBFooter
      ref={ref}
      bgColor="light"
      className={`text-center text-lg-start text-muted ${
        showAnimation ? "your-component fade-in" : ""
      }`}
      id={id}
    >
      <section className=" p-4 border-bottom"></section>

      <section className="">
        <MDBContainer
          className="text-center text-md-start mt-5"
          style={{
            flexDirection: "column",
          }}
        >
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <LiaGemSolid className="me-3" />
                PEEP Group
              </h6>
              {/* <p>"Prime Events Exhibitions & Productions"</p> */}
              <img src={logo} alt="" style={{ width: "300px" }} />
            </MDBCol>
            <MDBCol md="3" lg="2" xl="3" className="mx-auto mb-3">
              <h6 className="text-uppercase fw-bold mb-3">Reach Us Out</h6>
              <p style={{ textAlign: "justify" }}>
                "Investing time and resources in innovation, we nurture a
                dynamic and sustainable culture that thrives on uniqueness. Our
                events and exhibitions aren't just gatherings; they're
                captivating experiences that redefine expectations.
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{
                  marginLeft: "14px",
                }}
              >
                GET IN TOUCH
              </h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://api.whatsapp.com/send/?phone=971581291260&text&type=phone_number&app_absent=0"
                  className="me-4 text-reset"
                >
                  <RiWhatsappFill fontSize={40} />
                </a>
                <a
                  href="https://www.instagram.com/peep.group?igshid=MXVyOWh2cGRhcWUweg%3D%3D&utm_source=qr"
                  className="me-4 text-reset"
                  target="blank"
                >
                  <AiFillInstagram fontSize={40} />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61550717297348&mibextid=LQQJ4d"
                  className="me-4 text-reset"
                  target="blank"
                >
                  <AiFillFacebook fontSize={40} />
                </a>
                <a
                  href="https://www.linkedin.com/company/prime-events-exhibitions-productions/"
                  className="me-4 text-reset"
                >
                  <AiFillLinkedin fontSize={40} />
                </a>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4 footer-mobile">
              <h6 className="text-uppercase fw-bold mb-4">
                <LiaGemSolid className="me-3" />
                United Arab Emirates
              </h6>
              <p>
                <RiMapPin2Fill className="me-3" />
                Ras Al Khor , DUBAI UAE
              </p>
              <p>
                <BsFillTelephoneFill className="me-3" />
                <span> +971581291260 </span>
              </p>
              <p>
                <MdEmail className="me-3" />
                info@peepgroup.com
              </p>
            </MDBCol>
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4 footer-mobile">
              <h6 className="text-uppercase fw-bold mb-4">
                <LiaGemSolid className="me-3" />
                Saudi Arabia
              </h6>
              <p>
                {/* <BiLocationPlus /> */}
                <RiMapPin2Fill className="me-3" />
                Al Batha , Riyadh KSA
              </p>
              <p>
                {/* <AiFillPhone />
                (+971) 50 60 72 888 */}
              </p>
              <p>
                <MdEmail className="me-3" />
                info@peepgroup.com
              </p>
            </MDBCol>
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4 footer-mobile">
              <h6 className="text-uppercase fw-bold mb-4">
                <LiaGemSolid className="me-3" />
                Qatar
              </h6>
              <p>
                <RiMapPin2Fill className="me-3" />
                Al Hazm , Doha Qatar
              </p>
              <p>
                {/* <AiFillPhone /> */}
                {/* (+971) 50 60 72 888 */}
              </p>
              <p>
                <MdEmail className="me-3" />
                info@peepgroup.com
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      {/* <hr /> */}
      <div
        style={{
          display: "flex",
          background: "#000",
          textAlign: "justify",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: "white",
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Copyrights &copy; {currentYear} Prime Event Exhibitions & Productions,
          All Rights Reserved.
        </p>
      </div>
    </MDBFooter>
  );
};
