import { ExploreSlider } from "../../../components";

//slider images

import product3 from "../../../assets/explore-slider/product3.webp";
import product4 from "../../../assets/explore-slider/product4.jpg";
import product5 from "../../../assets/explore-slider/product5.webp";
import product6 from "../../../assets/explore-slider/product6.webp";

export const ProductLaunches = () => {
  const sliderImages = [product3, product4, product5, product6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
