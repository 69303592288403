import "./style.scss";

import { CultureBlock } from "../index";
export const CultureContainer = () => {
  return (
    <div>
      <br></br>
      <br></br>
      {/* <p className="heading ">Who are we</p> */}
      <p className="heading2">Why Choose Us ?</p>
      <br></br>
      <br></br>
      <br></br>
      <CultureBlock />
    </div>
  );
};
