import React, { useState, useEffect, useRef } from "react"; // Added useRef
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";

export const NavBar = ({ onDropdownSelect }) => {
  const handleDropdownSelection = (selectedItem) => {
    if (onDropdownSelect) {
      onDropdownSelect(selectedItem);
    }
    setShowDropdown(false);
  };

  const [bgColor, setBgColor] = useState({
    bgColor: "transparent",
    color: "#fff",
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const navbarRef = useRef(null); // Added useRef for navbar

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 200) {
      navbarRef.current.style.display = "none"; // Hide navbar
    } else {
      navbarRef.current.style.display = "flex"; // Show navbar
      setBgColor({ bgColor: "transparent", color: "#fff" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navStyle = {
    padding: "10px 60px",
    backgroundColor: bgColor.bgColor,
    transition: "0.5s",
  };

  const linkStyle = {
    textDecoration: "none",
    padding: "15px 10px",
    color: bgColor.color,
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
  };

  return (
    <>
      <Navbar ref={navbarRef} expand="lg" style={navStyle}>
        {/* Added ref to Navbar */}
        <Navbar.Brand style={{ width: "80%" }} href="/">
          <div className="logo-text">
            Prime Events Exhibitions & Productions
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ justifyContent: "end" }}
        >
          <Nav className="ml-auto">
            <Navbar.Brand style={linkStyle} href="/">
              Home
            </Navbar.Brand>
            <Nav.Link style={linkStyle} href="/about">
              About
            </Nav.Link>

            <Dropdown
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
              show={showDropdown}
              className="service-dropdown"
            >
              <Dropdown.Toggle
                as={RouterLink}
                to={"/services"}
                style={linkStyle}
              >
                Services
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={RouterLink}
                  onClick={() => {
                    handleDropdownSelection("Audio Solutions");
                    window.location.href =
                      "/technical-expertise/audio-solutions";
                  }}
                >
                  Audio Solutions
                </Dropdown.Item>
                <Dropdown.Item
                  as={RouterLink}
                  onClick={() => {
                    handleDropdownSelection("Video Solutions");
                    window.location.href =
                      "/technical-expertise/video-solutions";
                  }}
                >
                  Video Solutions
                </Dropdown.Item>
                <Dropdown.Item
                  as={RouterLink}
                  onClick={() => {
                    handleDropdownSelection("Light Solutions");
                    window.location.href =
                      "/technical-expertise/light-solutions";
                  }}
                >
                  Lighting Solutions
                </Dropdown.Item>
                <Dropdown.Item
                  as={RouterLink}
                  onClick={() => {
                    handleDropdownSelection("Trussing Solutions");
                    window.location.href =
                      "/technical-expertise/trussing-solutions";
                  }}
                >
                  Trussing Solutions
                </Dropdown.Item>
                <Dropdown.Item
                  as={RouterLink}
                  onClick={() => {
                    handleDropdownSelection("Stagging Solutions");
                    window.location.href =
                      "/technical-expertise/stagging-solutions";
                  }}
                >
                  Stagging Solutions
                </Dropdown.Item>
                <Dropdown.Item
                  as={RouterLink}
                  onClick={() => {
                    handleDropdownSelection("Media Solutions");
                    window.location.href =
                      "/technical-expertise/media-solutions";
                  }}
                >
                  Media Solutions
                </Dropdown.Item>
                <Dropdown.Item
                  as={RouterLink}
                  onClick={() => {
                    handleDropdownSelection("Production Solutions");
                    window.location.href =
                      "/technical-expertise/production-solutions";
                  }}
                >
                  Production & Cladding
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link style={linkStyle} href="/gallery">
              Gallery
            </Nav.Link>
            <Nav.Link style={linkStyle} href="/send-query">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
