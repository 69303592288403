import { ExploreSlider } from "../../../components";

//slider images
import trade3 from "../../../assets/explore-slider/trade3.webp";
import trade4 from "../../../assets/explore-slider/trade4.webp";
import trade5 from "../../../assets/explore-slider/trade5.webp";
import trade6 from "../../../assets/explore-slider/trade6.webp";

export const Trades = () => {
  const sliderImages = [trade3, trade4, trade5, trade6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
