import { useState } from "react";
import { Image } from "react-bootstrap";
import { WorkBlock, VideoContainer } from "../../components";

import map from "../../assets/Images/map.png";
import vid1 from "../../assets/vid1.mp4";
import vid2 from "../../assets/vid2.mp4";
// import vid3 from "../../assets/vid3.mp4";

//slider images
import event1 from "../../assets/explore-slider/liveevents1.webp";
import event2 from "../../assets/explore-slider/liveevents2.webp";
import event3 from "../../assets/explore-slider/liveevents3.jpg";
import event4 from "../../assets/explore-slider/liveevents4.webp";
import event5 from "../../assets/explore-slider/liveevents5.webp";
import event6 from "../../assets/explore-slider/liveevents6.jpg";

import exhibition1 from "../../assets/explore-slider/exhibition1.webp";
import exhibition2 from "../../assets/explore-slider/exhibition2.webp";
import exhibition3 from "../../assets/explore-slider/exhibition3.webp";
import exhibition4 from "../../assets/explore-slider/exhibition4.webp";
import exhibition5 from "../../assets/explore-slider/exhibition5.webp";
import exhibition6 from "../../assets/explore-slider/exhibition6.webp";

import wedding1 from "../../assets/explore-slider/weddings1.webp";
import wedding2 from "../../assets/explore-slider/weddings2.webp";
import wedding3 from "../../assets/explore-slider/weddings3.webp";
import wedding4 from "../../assets/explore-slider/weddings4.webp";
import wedding5 from "../../assets/explore-slider/weddings5.webp";
import wedding6 from "../../assets/explore-slider/weddings6.webp";

import conference1 from "../../assets/explore-slider/conference1.webp";
import conference2 from "../../assets/explore-slider/conference2.webp";
import conference3 from "../../assets/explore-slider/conference3.webp";
import conference4 from "../../assets/explore-slider/conference4.webp";
import conference5 from "../../assets/explore-slider/conference5.webp";
import conference6 from "../../assets/explore-slider/conference6.webp";

import gala1 from "../../assets/explore-slider/galadinner1.webp";
import gala2 from "../../assets/explore-slider/galadinner2.webp";
import gala3 from "../../assets/explore-slider/galadinner3.webp";
import gala4 from "../../assets/explore-slider/galadinner4.webp";
import gala5 from "../../assets/explore-slider/galadinner5.webp";
import gala6 from "../../assets/explore-slider/galadinner6.webp";

import product1 from "../../assets/explore-slider/product1.webp";
import product2 from "../../assets/explore-slider/product2.webp";
import product3 from "../../assets/explore-slider/product3.webp";
import product4 from "../../assets/explore-slider/product4.jpg";
import product5 from "../../assets/explore-slider/product5.webp";
import product6 from "../../assets/explore-slider/product6.webp";

import award1 from "../../assets/explore-slider/award1.webp";
import award2 from "../../assets/explore-slider/award2.webp";
import award3 from "../../assets/explore-slider/award3.webp";
import award4 from "../../assets/explore-slider/award4.webp";
import award5 from "../../assets/explore-slider/award5.webp";
import award6 from "../../assets/explore-slider/award6.webp";

import seminar1 from "../../assets/explore-slider/seminar1.webp";
import seminar2 from "../../assets/explore-slider/seminar2.webp";
import seminar3 from "../../assets/explore-slider/seminar3.webp";
import seminar4 from "../../assets/explore-slider/seminar4.webp";
import seminar5 from "../../assets/explore-slider/seminar5.webp";
import seminar6 from "../../assets/explore-slider/seminar6.webp";

import festival1 from "../../assets/explore-slider/festival1.webp";
import festival2 from "../../assets/explore-slider/festival2.webp";
import festival3 from "../../assets/explore-slider/festival3.webp";
import festival4 from "../../assets/explore-slider/festival4.webp";
import festival5 from "../../assets/explore-slider/festival5.webp";
import festival6 from "../../assets/explore-slider/festival6.webp";

import trade1 from "../../assets/explore-slider/trade1.webp";
import trade2 from "../../assets/explore-slider/trade2.webp";
import trade3 from "../../assets/explore-slider/trade3.webp";
import trade4 from "../../assets/explore-slider/trade4.webp";
import trade5 from "../../assets/explore-slider/trade5.webp";
import trade6 from "../../assets/explore-slider/trade6.webp";

import design1 from "../../assets/explore-slider/design1.webp";
import design2 from "../../assets/explore-slider/design2.webp";
import design3 from "../../assets/explore-slider/design3.jpg";
import design4 from "../../assets/explore-slider/design4.webp";
import design5 from "../../assets/explore-slider/design5.webp";
import design6 from "../../assets/explore-slider/design6.webp";

import fitout1 from "../../assets/explore-slider/fitout1.webp";
import fitout2 from "../../assets/explore-slider/fitout2.webp";
import fitout3 from "../../assets/explore-slider/fitout3.webp";
import fitout4 from "../../assets/explore-slider/fitout4.webp";
import fitout5 from "../../assets/explore-slider/fitout5.jpg";
import fitout6 from "../../assets/explore-slider/fitout6.webp";

import bgImg from "../../assets/explore-slider/services.webp";

export const Services = () => {
  const [vidLink, setVidLink] = useState(vid1);

  const videoLinkSetter = () => {
    console.log("Coming to this block");
    setVidLink(vid2);
  };

  const imgObject = [
    {
      col: [
        {
          img1: event1,
          img2: event2,
          text1: "Live Events",
          sliderImages: [event3, event4, event5, event6],
          link: "/services/live-events",
        },
        {
          img1: exhibition1,
          img2: exhibition2,
          text1: "Exhibitions",
          sliderImages: [exhibition3, exhibition4, exhibition5, exhibition6],
          link: "/services/exhibitions",
        },
        {
          img1: wedding2,
          img2: wedding1,
          text1: "Weddings & Parties",
          sliderImages: [wedding3, wedding4, wedding5, wedding6],
          link: "/services/weddings",
        },
      ],
    },
    {
      col: [
        {
          img1: conference1,
          img2: conference2,
          text1: "Conferences",
          sliderImages: [conference3, conference4, conference5, conference6],
          link: "/services/conferences",
        },
        {
          img1: gala1,
          img2: gala2,
          text1: "Gala Dinners",
          sliderImages: [gala3, gala4, gala5, gala6],
          link: "/services/gala-dinners",
        },
        {
          img1: product1,
          img2: product2,
          text1: "Product Launches ",
          sliderImages: [product3, product4, product5, product6],
          link: "/services/product-launches",
        },
      ],
    },
    {
      col: [
        {
          img1: award2,
          img2: award1,
          text1: "Award Shows & Ceremonies",
          sliderImages: [award3, award4, award5, award6],
          link: "/services/awards",
        },
        {
          img1: seminar1,
          img2: seminar2,
          text1: "Seminars",
          sliderImages: [seminar3, seminar4, seminar5, seminar6],
          link: "/services/seminars",
        },
        {
          img1: festival2,
          img2: festival1,
          text1: "Festival & Concerts",
          sliderImages: [festival3, festival4, festival5, festival6],
          link: "/services/festivals",
        },
      ],
    },
    {
      col: [
        {
          img1: trade1,
          img2: trade2,
          text1: "Trade Shows & Road Shows",
          sliderImages: [trade3, trade4, trade5, trade6],
          link: "/services/trade-shows",
        },
        {
          img1: fitout1,
          img2: fitout2,
          text1: "Production & Designing",
          sliderImages: [design3, design4, design5, design6],
          link: "/services/production-designing",
        },
        {
          img1: design1,
          img2: design2,
          text1: "Interior & Fit-Outs",
          sliderImages: [fitout3, fitout4, fitout5, fitout6],
          link: "/services/interior-fitouts",
          text3: "Learn more",
        },
      ],
    },
  ];

  return (
    <div>
      <Image
        style={{
          objectFit: "cover",
          position: "absolute",
          zIndex: -1,
        }}
        src={bgImg}
        width={"100%"}
        height={"500"}
      />
      <div className="aboutUs">
        <p className="about">SERVICES</p>
        <p className="discription">
          WE HANDLE ALL YOUR EVENTS, BIG OR SMALL. FROM CREATION TO
          ORGANIZATION, ACTIVATIONS TO CONFERENCES, WE ENSURE MEMORABLE AND
          WELL-EXECUTED EXPERIENCES
        </p>
      </div>
      <center>
        <p
          style={{
            marginTop: "50px",
            fontSize: "22px",
            lineHeight: "10px",
          }}
        >
          TOP-NOTCH
        </p>
        <p
          style={{
            fontSize: "30px",
            lineHeight: "15px",
          }}
        >
          EVENTS SERVICES
        </p>
      </center>
      <WorkBlock imgObject={imgObject} showButton={true} />
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <div>
          <center>
            <p
              style={{
                fontSize: "30px",
                fontFamily: "cursive",
              }}
            >
              MANAGING
            </p>
            <p
              style={{
                fontSize: "40px",
                fontFamily: "cursive",
              }}
            >
              WORLDWIDE EVENTS
            </p>
          </center>
        </div>
        <div>
          <img src={map} alt="" width={800} />
        </div>
      </div> */}
      {/* <VideoContainer vidLink={vidLink} setVidLink={() => videoLinkSetter()} /> */}
    </div>
  );
};
