import { ExploreSlider } from "../../../components";

//slider images
import seminar3 from "../../../assets/explore-slider/seminar3.webp";
import seminar4 from "../../../assets/explore-slider/seminar4.webp";
import seminar5 from "../../../assets/explore-slider/seminar5.webp";
import seminar6 from "../../../assets/explore-slider/seminar6.webp";

export const Seminars = () => {
  const sliderImages = [seminar3, seminar4, seminar5, seminar6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
