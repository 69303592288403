import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Image,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import bgImg from "../../assets/query.png";
import "./style.scss";

export const SendQuery = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    questionType: [],
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "questionType") {
      const selectedType = e.target.value;
      const updatedTypes = formData.questionType.includes(selectedType)
        ? formData.questionType.filter((type) => type !== selectedType)
        : [...formData.questionType, selectedType];

      setFormData({ ...formData, questionType: updatedTypes });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://backend.peepgroup.com/api/send-mail",
        formData
      );
      console.log(response);

      // Clear the form
      setFormData({
        name: "",
        email: "",
        questionType: [],
        message: "",
      });

      // Show success alert for 3 seconds
      setTimeout(() => {
        alert("Your query has been submitted successfully!");
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div></div>
      <div
        style={{
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          src={bgImg}
          className="questionImage"
          style={{
            width: "35%",
            height: "300px",
            opacity: 0.9,
            marginTop: "2rem",
          }}
        />
      </div>
      <section>
        <Container className="send-query-container mt-5">
          <Row className="justify-content-md-center" style={{ width: "100%" }}>
            <Col md={8} lg={6}>
              <h2 className="text-center">Send us your Query</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>
                    <b>Your Name:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>
                    <b>Your Email:</b>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    pattern="^[A-Za-z]+@[A-Za-z]+\.[A-Za-z]+$"
                    title="Enter a valid email address"
                    required
                  />
                </Form.Group>

                <Form.Label>
                  <b>I have a question about:</b>
                </Form.Label>
                <fieldset>
                  <Form.Group>
                    {[
                      "Live Events",
                      "Exhibitions",
                      "Weddings",
                      "Conferences",
                      "Gala Dinners",
                      "Product Launch",
                      "Award Shows",
                      "Ceremonies",
                      "Seminars",
                      "Festivals",
                      "Parties",
                      "Trade Shows",
                      "Road Shows",
                      "Productions",
                      "Designs",
                      "Interior & Fitout",
                      "Audio Solutions",
                      "Video Solutions",
                      "Lighting Solutions",
                      "Trussing Solutions",
                      "Stagging Solutions",
                      "Media Solutions",
                      "Fabrication Solutions",
                      "Cladding Solutions",
                    ].map((type, index) => (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        label={type}
                        name="questionType"
                        value={type}
                        checked={formData.questionType.includes(type)}
                        onChange={handleChange}
                      />
                    ))}
                  </Form.Group>
                </fieldset>

                <Form.Group controlId="formMessage">
                  <Form.Label>
                    <b>Message or Questions</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter your message or question here..."
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Submitting...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
