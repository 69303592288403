import React, { useState, useEffect } from "react";
import { ExploreSlider } from "../../../components";
// import { imageObj } from "../../components/data";
import "./style.scss";

export const StaggingSolutions = ({ imageObj }) => {
  const [selectedImages, setSelectedImages] = useState(
    imageObj[4].sliderImages
  );
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(
    imageObj[4].serviceName
  );

  const [hideHeading, setHideHeading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHideHeading(true);
    }, 4000);
  }, []);

  return (
    <div>
      <section style={{ background: "#000" }}>
        <div
          className="dropdown-message"
          style={{ display: hideHeading ? "none" : "" }}
        >{`${selectedDropdownItem}`}</div>
        <ExploreSlider images={selectedImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
