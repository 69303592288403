import { ExploreSlider } from "../../../components";

//slider images

import award3 from "../../../assets/explore-slider/award3.webp";
import award4 from "../../../assets/explore-slider/award4.webp";
import award5 from "../../../assets/explore-slider/award5.webp";
import award6 from "../../../assets/explore-slider/award6.webp";

export const Awards = () => {
  const sliderImages = [award3, award4, award5, award6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
