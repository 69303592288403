import React, { useState, useEffect } from "react";

// import { Image, Container } from "react-bootstrap";
import { HomeSlider } from "../../components/home-slider";
import { OurStory } from "../../components/our-story";
import { QueryModal } from "../../components/queryModal";
import { NavBar, Footer, WorkBlock } from "../../components";

// import vid from "../../assets/main.mp4";
import event1 from "../../assets/home-page/event1.webp";
import event2 from "../../assets/home-page/event2.webp";
import exhibition1 from "../../assets/home-page/exhibition1.webp";
import exhibition2 from "../../assets/home-page/exhibition2.webp";
import production1 from "../../assets/home-page/production1.webp";
import production2 from "../../assets/home-page/production2.webp";
// import logo from "../../assets/pop.jpeg";

//home page pictures
import block1 from "../../assets/home-page/block1.webp";
import block2 from "../../assets/home-page/block2.webp";
import block3 from "../../assets/home-page/block3.webp";
import block4 from "../../assets/home-page/block4.webp";
import block5 from "../../assets/home-page/block5.webp";
import block6 from "../../assets/home-page/block6.webp";
import block7 from "../../assets/home-page/block7.webp";
import block8 from "../../assets/home-page/block8.webp";
import block9 from "../../assets/home-page/block9.webp";
import block10 from "../../assets/home-page/block10.webp";
import block11 from "../../assets/home-page/block11.webp";
import block12 from "../../assets/home-page/block12.webp";
import block13 from "../../assets/home-page/block13.webp";
import block14 from "../../assets/home-page/block14.webp";
import block15 from "../../assets/home-page/block15.webp";

import "./style.scss";

export const HomePage = () => {
  // const [showLogo, setShowLogo] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const imgObject = [
    {
      col: [
        {
          img1: event2,
          img2: event1,
          text1: "EVENTS",
        },
        {
          img1: exhibition2,
          img2: exhibition1,
          text1: "EXHIBITIONS",
        },
        {
          img1: production2,
          img2: production1,
          text1: "PRODUCTIONS",
        },
      ],
    },
  ];

  const imgObject2 = [
    {
      col: [
        {
          img1: block1,
          img2: block1,
          text2: "A Dazzling Product Launch Spectacle",
        },
        {
          img1: block2,
          img2: block2,
          text2: "Exclusive Premiere of Egyptian Elegance",
        },
        {
          img1: block3,
          img2: block3,
          text2: "Unforgettable Day of Excitement at SeaWorld",
        },
      ],
    },
    {
      col: [
        {
          img1: block4,
          img2: block4,
          text2: "Festive Summer Celebration",
        },
        {
          img1: block5,
          img2: block5,
          text2: "Distinguished Commencement Ceremony for Abu Dhabi Police",
        },
        {
          img1: block6,
          img2: block6,
          text2: "Expert Sessions",
        },
      ],
    },
    {
      col: [
        {
          img1: block7,
          img2: block7,
          text2: "Nurturing Young Minds in the World of Choas",
        },
        {
          img1: block8,
          img2: block8,
          text2: "Impressive Flashmob Delight",
        },
        {
          img1: block9,
          img2: block9,
          text2: "Elegance Redefined in the Legacy of Fashion",
        },
      ],
    },
    {
      col: [
        {
          img1: block10,
          img2: block10,
          text2: "Grand Unveilingi",
        },
        {
          img1: block11,
          img2: block11,
          text2: "Sumptuous Suhoor",
        },
        {
          img1: block12,
          img2: block12,
          text2: "Luxurious Relaunch Celebration",
        },
      ],
    },
    {
      col: [
        {
          img1: block13,
          img2: block13,
          text2: "Celebratory Openings",
        },
        {
          img1: block14,
          img2: block14,
          text2: "Epic Customer Experience Grand Launch",
        },
        {
          img1: block15,
          img2: block15,
          text2: "Honoring Excellence at the Police Academy Gala",
        },
      ],
    },
  ];

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowLogo(false);
  //   }, 2000);
  // });

  return (
    <>
      {/* {showLogo ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <center>
            <Image className="centered-image" src={logo} width={200} />
          </center>
        </div>
      ) : ( */}
      <div>
        <section>
          <HomeSlider />
        </section>
        <section>
          <WorkBlock
            key="ourservice"
            textOne="OUR SERVICES"
            textTwo="WHAT WE DO"
            imgObject={imgObject}
            style={{
              marginTop: "10px",
            }}
          />
        </section>
        <section>
          <OurStory />
        </section>
        <section>
          <WorkBlock
            key="ourwork"
            textOne="OUR WORK"
            textTwo="FEATURED EVENTS"
            imgObject={imgObject2}
          />
        </section>
        <section>
          {/* <Container>
          <div className="imgContainer1">
            <Image src={img22} width={200} />
            <Image src={img33} width={200} />
            <Image src={img44} width={200} />
            <Image src={img55} width={200} />
            <Image src={img66} width={200} />
            <Image src={img22} width={200} />
            <Image src={img33} width={200} />
            <Image src={img44} width={200} />
            <Image src={img66} width={200} />
          </div>
        </Container> */}
        </section>
        <section>
          <section>
            <QueryModal show={show} handleClose={handleClose} />
          </section>
        </section>
      </div>
      {/* )} */}
    </>
  );
};
