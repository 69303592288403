import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
// import img from "../../assets/Images/2.png";

import "./style.scss";

export const DisplayBox = ({
  img1,
  img2,
  text1,
  text2,
  fontSize,
  showButton,
  sliderImages,
  link,
}) => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    // Set showAnimation to true after the component has mounted
    setShowAnimation(true);
  }, []);
  return (
    <div
      className={`your-component ${showAnimation ? "fade-in" : ""}`}
      style={{
        position: "relative",
      }}
    >
      <div className="image-container">
        <Image
          src={img1}
          alt="Your Image"
          width={"100%"}
          height={"290px"}
          style={{
            width: "100%",
            height: "290px",
            // borderRadius: "15px",
          }}
        />
        <div
          className="overlay-text"
          style={fontSize ? { fontSize: fontSize } : null}
        >
          {text2}
        </div>
      </div>
      <div
        className="overLayImage"
        style={{
          width: "100%",
          height: "290px",
          borderRadius: "10px",
        }}
      >
        <Image
          src={img2}
          style={{
            width: "100%",
            height: "290px",
            borderRadius: "10px",
          }}
        />
        <h3 className="overlay-text1">{text1}</h3>
      </div>

      {showButton && (
        <div className="btn">
          {/* <Link to={"/service/live-event"} state={sliderImages}> */}
          <button
            className="explore-more-button"
            onClick={() => {
              window.location.href = link;
            }}
          >
            Explore here
          </button>
          {/* </Link> */}
        </div>
      )}
    </div>
  );
};
