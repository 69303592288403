import React from "react";
import Slider from "react-slick";
import { Image } from "react-bootstrap";

import client1 from "../../assets/clients/client1.png";
import client2 from "../../assets/clients/client2.png";
import client3 from "../../assets/clients/client3.png";
import client4 from "../../assets/clients/client4.png";
import client5 from "../../assets/clients/client5.png";
import client6 from "../../assets/clients/client6.png";
import client7 from "../../assets/clients/client7.png";
import client8 from "../../assets/clients/client8.png";
import client9 from "../../assets/clients/client9.png";
import client10 from "../../assets/clients/client10.png";
import client11 from "../../assets/clients/client11.png";
import client12 from "../../assets/clients/client12.png";
import client13 from "../../assets/clients/client13.png";
import client14 from "../../assets/clients/client14.png";
import client15 from "../../assets/clients/client15.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ScrollSlider = () => {
  const settings = {
    dots: false,
    speed: 2000,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clients = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    client7,
    client8,
    client9,
    client10,
    client11,
    client12,
    client13,
    client14,
    client15,
  ];

  return (
    <div>
      <Slider {...settings} style={{ height: "200px" }}>
        {clients.map((client, index) => (
          <div key={index}>
            <Image
              src={client}
              alt={`Client ${index + 1}`}
              style={{
                width: "300px",
                height: "200px",
                objectFit: "contain",
              }}
              fluid
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
