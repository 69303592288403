import { Image } from "react-bootstrap";
import bgImg from "../../assets/gallery/gallery.jpeg";
import { WorkBlock, NavBar, Footer } from "../../components";

//project Images
import p1 from "../../assets/gallery/p1.webp";
import p2 from "../../assets/gallery/p2.webp";
import p3 from "../../assets/gallery/p3.webp";
import p4 from "../../assets/gallery/p4.webp";
import p5 from "../../assets/gallery/p5.webp";
import p6 from "../../assets/gallery/p6.webp";
import p7 from "../../assets/gallery/p7.webp";
import p8 from "../../assets/gallery/p8.webp";
import p9 from "../../assets/gallery/p9.webp";
import p10 from "../../assets/gallery/p10.webp";
import p11 from "../../assets/gallery/p11.webp";
import p12 from "../../assets/gallery/p12.webp";
import p13 from "../../assets/gallery/p13.webp";
import p14 from "../../assets/gallery/p14.webp";
import p15 from "../../assets/gallery/p15.webp";
import p16 from "../../assets/gallery/p16.webp";
import p17 from "../../assets/gallery/p17.webp";
import p18 from "../../assets/gallery/p18.webp";
import p19 from "../../assets/gallery/p19.webp";
import p20 from "../../assets/gallery/p20.webp";
import p21 from "../../assets/gallery/p21.webp";
import p22 from "../../assets/gallery/p22.webp";
import p23 from "../../assets/gallery/p23.webp";
import p24 from "../../assets/gallery/p24.webp";
import p25 from "../../assets/gallery/p25.webp";
import p26 from "../../assets/gallery/p26.webp";
import p27 from "../../assets/gallery/p27.webp";
import p28 from "../../assets/gallery/p28.webp";
import p29 from "../../assets/gallery/p29.webp";
import p30 from "../../assets/gallery/p30.webp";

import "./style.scss";

export const Gallery = () => {
  const imgObject = [
    {
      col: [
        { img1: p1, img2: p2 },
        { img1: p3, img2: p4 },
        { img1: p5, img2: p6 },
      ],
    },
    {
      col: [
        { img1: p7, img2: p8 },
        { img1: p9, img2: p10 },
        { img1: p11, img2: p12 },
      ],
    },
    {
      col: [
        { img1: p13, img2: p14 },
        { img1: p15, img2: p16 },
        { img1: p17, img2: p18 },
      ],
    },
    {
      col: [
        { img1: p19, img2: p20 },
        { img1: p21, img2: p22 },
        { img1: p23, img2: p24 },
      ],
    },
    {
      col: [
        { img1: p25, img2: p26 },
        { img1: p27, img2: p28 },
        { img1: p29, img2: p30 },
      ],
    },
  ];

  return (
    <div className="gallery">
      <Image
        className="cover-img"
        style={{
          objectFit: "cover",
          position: "absolute",
          zIndex: -1,
        }}
        src={bgImg}
        width={"100%"}
        height={"500"}
      />
      <div className="aboutUs">
        <p className="about">GALLERY</p>
        <p className="discription">
          TURNING IDEAS INTO REALITY WITH OUR EXPERT EVENTS TEAM
        </p>
      </div>
      <WorkBlock imgObject={imgObject} className="work-block" />
    </div>
  );
};
