import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

export const ExploreSlider = ({ images = [] }) => {
  const settings = {
    dots: true,
    speed: 500,
    fade: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    cssEase: "linear",
    swipeToSlide: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="explore-slider-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="explore-slider-item">
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};
