import { ExploreSlider } from "../../../components";

//slider images

import event3 from "../../../assets/explore-slider/liveevents3.jpg";
import event4 from "../../../assets/explore-slider/liveevents4.webp";
import event5 from "../../../assets/explore-slider/liveevents5.webp";
import event6 from "../../../assets/explore-slider/liveevents6.jpg";

export const LiveEvent = () => {
  const sliderImages = [event3, event4, event5, event6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
