import { ExploreSlider } from "../../../components";

//slider images
import design3 from "../../../assets/explore-slider/design3.jpg";
import design4 from "../../../assets/explore-slider/design4.webp";
import design5 from "../../../assets/explore-slider/design5.webp";
import design6 from "../../../assets/explore-slider/design6.webp";

export const Production = () => {
  const sliderImages = [design3, design4, design5, design6];
  console.log(sliderImages);
  return (
    <div>
      <section>
        <ExploreSlider images={sliderImages} style={{ width: "100%" }} />
      </section>
    </div>
  );
};
